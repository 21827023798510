export function convertISO8601ToMinutesSeconds(duration: string) {
  const regex = /PT(\d+H)?(\d+M)?(\d+S)?/; // Regex to extract hours, minutes, and seconds from ISO 8601 duration

  const match = duration.match(regex); // Match the pattern

  if (match) {
    const hours = match[1] ? parseInt(match[1]) : 0; // Extract hours, default to 0 if not present
    const minutes = match[2] ? parseInt(match[2]) : 0; // Extract minutes, default to 0 if not present
    const seconds = match[3] ? parseInt(match[3]) : 0; // Extract seconds, default to 0 if not present

    const totalMinutes = hours * 60 + minutes + seconds / 60; // Convert hours and seconds to minutes and add to total

    return {
      totalMinutes: totalMinutes.toFixed(2), // Return total minutes rounded to 2 decimal places
      hours,
      minutes,
      seconds,
    };
  } else {
    return null; // Return null for invalid input
  }
}
