import Link from 'next/link';
import { useRouter } from 'next/router';
import { Media } from '@/components/media';
import type { PostCollection } from '@/payload-types';

import { getIcon } from './collection-icons';

export const Collection: React.FC<{ postCollection: PostCollection }> = ({
  postCollection,
}) => {
  return (
    <div className="h-full cursor-pointer border border-slate-200 p-6 transition-all duration-300 hover:bg-slate-50">
      <div className="flex flex-col items-center gap-y-4">
        <div className="">
          {postCollection.image && typeof postCollection.image !== 'string' && (
            <Media
              resource={postCollection.image}
              imgClassName="aspect-square h-16 w-16"
            />
          )}
        </div>
        <p className="mt-auto text-center font-bold text-[#193CEB] lg:text-2xl lg:font-extrabold">
          {postCollection?.title}
        </p>
      </div>
    </div>
  );
};

export const CollectionsMini: React.FC<{
  postCollections: PostCollection[];
}> = (props) => {
  const { locale } = useRouter();

  return (
    <div className="mt-12 grid grid-cols-2 gap-6 lg:mt-16 lg:grid-cols-4">
      {[...props.postCollections]
        .sort((a, b) => a.order - b.order)
        .map((collection) => {
          return (
            <Link
              href={`/collections/${collection.slug}`}
              key={collection.slug}
              locale={locale}
            >
              <Collection postCollection={collection} />
            </Link>
          );
        })}
    </div>
  );
};

export default CollectionsMini;
