import { useCallback } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from '@/components/carousel/EmblaArrowButton';
import type { Post } from '@/payload-types';
import Autoplay from 'embla-carousel-autoplay';
import type { EmblaCarouselType } from 'embla-carousel-react';
import useEmblaCarousel from 'embla-carousel-react';
import { useTranslation } from 'next-i18next';

import PostCardVertical from '../posts/post-card-vertical';

const PostsByCollection: React.FC<{
  posts: {
    post: Partial<Post>;
  }[];
}> = ({ posts }) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: false, slidesToScroll: 3, containScroll: 'trimSnaps' },
    [Autoplay({ delay: 5000 })],
  );

  const onButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
    const { autoplay } = emblaApi.plugins();
    if (!autoplay) return;
    if (autoplay.options.stopOnInteraction !== false) autoplay.stop();
  }, []);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi, onButtonClick);

  return (
    <>
      {posts && (
        <div className="lg:mt-16 lg:block">
          <div className="flex flex-row items-center gap-x-2">
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />

            <div className="w-[1152px] overflow-hidden" ref={emblaRef}>
              <div className="pan-y mx-2 hidden gap-x-2 lg:flex">
                {posts.map((post, index) => (
                  <div
                    className="relative min-w-0 flex-[0_0_33%] px-2"
                    key={index}
                  >
                    <Link
                      href={`/posts/${post?.post?.slug}`}
                      prefetch={false}
                      locale={locale}
                      key={post?.post?.id}
                    >
                      <PostCardVertical post={post?.post} />
                    </Link>
                  </div>
                ))}
              </div>
            </div>

            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </div>

          <div className="hidden text-center lg:mt-16 lg:block">
            <Link href="/posts" locale={locale}>
              <button className="border-brand-500 text-brand-500 hover:text-brand-600 inline-flex items-center justify-center rounded-full border-2 bg-white px-6 py-3 font-semibold ring-offset-white transition-colors hover:bg-slate-100 hover:bg-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
                {t('seeMoreArticles')}
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};
export default PostsByCollection;
