import { Media } from '@/components/media';
import { Icons } from '@/components/ui/icons';
import useReadingTime from '@/hooks/useReadingTime';
import type { Post } from '@/payload-types';
import formatDate from '@/utils/formatDate';
import { DotIcon } from 'lucide-react';
import { useTranslation } from 'next-i18next';

import PostDuration from './details/post-duration';

export const PostCardHorizontal: React.FC<{
  post: Partial<Post>;
  hideFooter?: boolean;
}> = ({ post, hideFooter = false }) => {
  const { text } = useReadingTime(JSON.stringify(post?.content || {}));
  const { t } = useTranslation();

  return (
    <>
      {post?.type && (
        <p className="text-brand-600 text-xs font-semibold uppercase ">
          {t(post?.type)}
        </p>
      )}
      <article
        key={post?.id}
        className="space-between group mt-3 flex h-full w-full flex-row items-start justify-between"
      >
        <div className="flex flex-col justify-between gap-y-3 overflow-hidden pr-4">
          <div className="items-center">
            {post?.title && (
              <h2 className="line-clamp-2 cursor-pointer font-serif font-extrabold text-[#193CEB] group-hover:underline lg:text-2xl">
                {post?.title}
              </h2>
            )}
            {post.excerpt && (
              <p className="prose-sm lg:prose-xl prose-slate mt-2 line-clamp-1 opacity-80 md:line-clamp-2 lg:mt-4">
                {post.excerpt}
              </p>
            )}
          </div>

          {!hideFooter && (
            <div className="mt-2 flex flex-wrap items-center">
              {post?.publishedOn && (
                <time
                  dateTime={post?.publishedOn}
                  className="text-xs text-slate-500 lg:text-base"
                >
                  {formatDate(post?.publishedOn)}
                </time>
              )}

              <PostDuration post={post} post_type="horizontal" />
            </div>
          )}
        </div>

        <div className="relative shadow-xl">
          {post?.type === 'article' ? (
            post.image &&
            typeof post.image !== 'string' &&
            typeof post.image.url === 'string' ? (
              <Media
                resource={post.image}
                className="aspect-[1/1] w-full min-w-[72px] max-w-[72px] object-cover"
              />
            ) : (
              <img
                src="/images/placeholder.png"
                alt=""
                className="aspect-[1/1] w-full min-w-[72px] max-w-[72px] object-cover"
              />
            )
          ) : (
            <>
              <div className="absolute inset-0 z-10 flex items-center justify-center">
                <div className="rounded-full bg-slate-700/80 p-1 lg:py-2 lg:pl-2 lg:pr-1">
                  <Icons.Play className="h-6 w-6 text-white lg:h-12 lg:w-12" />
                </div>
              </div>
              <img
                src={`https://img.youtube.com/vi/${post?.youtube_id}/0.jpg`}
                alt=""
                className="aspect-[1/1] w-full min-w-[72px] max-w-[72px] object-cover"
              />
            </>
          )}
        </div>
      </article>
    </>
  );
};

export default PostCardHorizontal;
