import Head from "next/head";
import { useRouter } from "next/router";
import { NextSeo, NextSeoProps } from "next-seo";
import { fqdn } from "@/configs/siteConfigs";

interface IMetaProps extends NextSeoProps {}

const Meta = (props: IMetaProps) => {
  const router = useRouter();
  // const locales = ['en', 'ms']

  return (
    <>
      <Head>
        <link rel="alternate" hrefLang="en"  href={`${fqdn}/en${router.asPath}`} />
        <link rel="alternate" hrefLang="ms" href={`${fqdn}/ms${router.asPath}`} />
      </Head>
      <NextSeo {...props} />
    </>
  );
};

export { Meta };
