import Image from 'next/image';
import { useRouter } from 'next/router';
import { Media } from '@/components/media';
import { Icons } from '@/components/ui/icons';
import useReadingTime from '@/hooks/useReadingTime';
import type { Post } from '@/payload-types';
import formatDate from '@/utils/formatDate';
import { DotIcon } from 'lucide-react';
import { useTranslation } from 'next-i18next';
import PostDuration from './details/post-duration';

export const PostCardLargeHorizontal: React.FC<{
  post: Post;
  isGuide?: boolean;
}> = ({ post, isGuide = false }) => {
  const { text } = useReadingTime(JSON.stringify(post?.content || {}));
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <article
      key={post?.id}
      className="group relative isolate flex flex-col gap-8 lg:flex-row"
    >
      <div className="relative aspect-[16/9] shadow-xl sm:aspect-[2/1] lg:aspect-[4/3] lg:w-1/2 lg:shrink-0">
        {post?.type === 'article' ? (
          post.image && typeof post.image !== 'string' ? (
            <Media
              resource={post.image}
              className="absolute inset-0 h-full w-full  bg-gray-50 object-cover"
            />
          ) : (
            <Image
              src="https://images.pexels.com/photos/22804/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1600"
              alt=""
              className="absolute inset-0 h-full w-full  bg-gray-50 object-cover"
              fill
            />
          )
        ) : (
          <>
            <div className="absolute inset-0 z-10 flex items-center justify-center">
              <div className=" rounded-full bg-slate-700/80 py-2 pl-2 pr-1">
                <Icons.Play className="h-16 w-16 text-white" />
              </div>
            </div>
            <img
              src={`https://img.youtube.com/vi/${post?.youtube_id}/0.jpg`}
              alt=""
              className="absolute inset-0 h-full w-full  bg-gray-50 object-cover"
            />
          </>
        )}
      </div>

      <div className="">
        <div className="relative max-w-xl">
          <p className="text-brand-500 text-xs font-semibold uppercase tracking-tight lg:text-lg">
            {router.pathname === '/guides'
              ? post?.type === 'article'
                ? t('featuredGuide')
                : t('featuredGuide')
              : post?.type === 'article'
              ? t('featuredArticle')
              : t('featuredVideo')}
          </p>
          <h2 className="text-brandAlt-950 mt-2 whitespace-break-spaces font-serif text-lg  font-black [text-wrap:balance] group-hover:underline lg:mt-4 lg:text-5xl lg:leading-tight">
            {post?.title}
          </h2>
          {post.excerpt && (
            <p className=" prose lg:prose-xl prose-slate mt-2 line-clamp-4 opacity-80 lg:mt-4">
              {post.excerpt}
            </p>
          )}
        </div>

        <div className="mt-2 flex flex-row items-center lg:hidden">
          {post?.publishedOn && (
            <time
              dateTime={post?.publishedOn}
              className="text-xs text-slate-500 lg:text-base"
            >
              {formatDate(post?.publishedOn)}
            </time>
          )}

          <PostDuration post={post} post_type="large-horizontal" />
        </div>
      </div>
    </article>
  );
};

export default PostCardLargeHorizontal;
