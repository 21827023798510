import type { GetServerSideProps } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { YouTubeResponse } from '@/@types/youtube';
import { CallToAction } from '@/components/block/call-to-action';
import { Button } from '@/components/ui/button';
import { fqdn, siteConfigs } from '@/configs/siteConfigs';
import PostsByCollections from '@/features/home/PostsByCollections';
import Collections from '@/features/posts/collections-mini';
import PostCardHorizontal from '@/features/posts/post-card-horizontal';
import PostCardLargeHorizontal from '@/features/posts/post-card-large-horizontal';
import PostCardVertical from '@/features/posts/post-card-vertical';
import { Meta } from '@/features/seo/meta';
import { Main } from '@/features/ui/layouts/main';
import { addApolloState, initializeApollo } from '@/graphql';
import { PAGE } from '@/graphql/pages';
import { FEATURED_HOME, POSTS } from '@/graphql/posts';
import type {
  Category,
  FeaturedPost,
  Footer,
  MainMenu,
  Page,
  Post,
  PostCollection,
} from '@/payload-types';
import { convertISO8601ToMinutesSeconds } from '@/utils/formatISO8601';
import { formatISO } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const Index: React.FC<{
  page: Page;
  id: string;
  collection: string;
  mainMenu: MainMenu;
  footer: Footer;
  postCollections: PostCollection[];
  featuredPosts: Partial<FeaturedPost>;
  posts: Post[];
  postsByCollection: Post[];
  categories: Category[];
  preview?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const url = locale === 'ms' ? `${fqdn}` : `${fqdn}/${locale}`;

  return (
    <Main
      meta={
        <Meta
          title={t('home')}
          description={t('homePage.subtitle')}
          additionalMetaTags={[
            { content: siteConfigs.title!, property: 'twitter:title' },
            {
              content: t('homePage.subtitle'),
              property: 'twitter:description',
            },
            { content: `${fqdn}/logo.jpg`, property: 'twitter:image' },
          ]}
          openGraph={{
            url: url,
            title: siteConfigs.title,
            description: t('homePage.subtitle'),
          }}
        />
      }
    >
      <div className="mx-auto max-w-6xl">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="mx-auto mt-12 max-w-xs px-4 text-center sm:max-w-none lg:mt-32">
            <h1 className="font-serif text-3xl font-black capitalize text-[#193CEB] lg:text-6xl">
              {t('homePage.title')}
            </h1>
            <p className="text-brandAlt-950 pt-4 capitalize lg:pt-6 lg:text-2xl">
              {t('homePage.subtitle')}
            </p>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-6xl">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
          <Collections postCollections={props.postCollections} />
        </div>
      </div>

      {props.featuredPosts?.homeSlider && (
        <div className="mx-auto max-w-7xl">
          <div className="lg:max-w-8xl mx-auto max-w-2xl px-4">
            <PostsByCollections posts={props.featuredPosts?.homeSlider} />
          </div>
        </div>
      )}

      <div className="mx-auto max-w-6xl">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="mt-16 grid gap-4 gap-y-12 divide-y px-4 lg:mt-32 lg:gap-8 lg:gap-y-16">
            {props.featuredPosts?.homePage?.map((featuredPost, index) => {
              const { post } = featuredPost;
              if (post && typeof post !== 'string' && index === 0)
                return (
                  <Link
                    href={`/posts/${post.slug}`}
                    locale={locale}
                    key={post?.id}
                  >
                    <PostCardLargeHorizontal post={post} />
                  </Link>
                );
            })}
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-6xl">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="no-scrollbar mt-8 flex items-start gap-12 overflow-scroll px-4 lg:mt-16">
            {props.featuredPosts?.homePage?.map((featuredPost, index) => {
              const { post } = featuredPost;
              if (post && typeof post !== 'string' && index !== 0)
                return (
                  <div className="w-full">
                    <Link
                      href={`/posts/${post.slug}`}
                      locale={locale}
                      key={post?.id}
                    >
                      <PostCardVertical post={post} hideFooter />
                    </Link>
                  </div>
                );
            })}
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-6xl">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          {props.categories && (
            <div className="mt-16 px-4 lg:mt-32">
              <p className="text-brandAlt-950 font-serif text-xl font-black lg:text-center lg:text-6xl">
                {t('browseCategories')}
              </p>
              <div className="flex flex-wrap gap-4 pt-6 lg:justify-center lg:gap-10 lg:pt-14">
                {[...props.categories]
                  .sort((a, b) => a.order - b.order)
                  .map((category) => (
                    <Link
                      key={category.id}
                      href={{
                        pathname: '/posts',
                        query: { category: category.id },
                      }}
                      locale={locale}
                    >
                      <Button
                        key={category.id}
                        variant="ghost"
                        className="rounded-md bg-[#f5f7f8] p-2.5 text-[#193CEB] hover:bg-slate-200 lg:px-10 lg:py-8 lg:text-2xl"
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        {category.title}
                      </Button>
                    </Link>
                  ))}
              </div>
            </div>
          )}

          <div className="mt-16 px-4 lg:mt-32">
            <p className="text-brandAlt-950 font-serif text-xl font-black lg:text-center lg:text-6xl">
              {t('latestPosts')}
            </p>
          </div>
          <div className="no-scrollbar grid gap-4 divide-y overflow-hidden px-4 lg:mt-16 lg:grid-cols-3 lg:gap-8 lg:gap-y-12 lg:divide-none">
            {props.posts.map((post) => (
              <Link href={`/posts/${post.slug}`} locale={locale} key={post?.id}>
                <div className="hidden lg:block">
                  <PostCardVertical post={post} key={post?.id} />
                </div>
                <div className="mt-4 block first:mt-0 lg:hidden ">
                  <PostCardHorizontal post={post} key={post?.id} />
                </div>
              </Link>
            ))}
          </div>
          <div className="mt-8 text-center lg:mt-16">
            <Link href="/posts" locale={locale}>
              <button className="border-brand-500 text-brand-500 hover:text-brand-600 inline-flex items-center justify-center rounded-full border-2 bg-white px-6 py-3 font-semibold ring-offset-white transition-colors hover:bg-slate-100 hover:bg-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
                {t('seeMoreArticles')}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Main>
  );
};

// export const getStaticProps: GetStaticProps = async ({ locale, params }) => {
export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const apolloClient = initializeApollo();
  const slug = 'home';

  const { data } = await apolloClient.query({
    query: PAGE,
    variables: {
      slug,
      locale,
    },
  });

  const { data: latestPosts } = await apolloClient.query({
    query: POSTS,
    variables: {
      locale,
      limit: 9,
    },
  });

  const defaultCollection = data.PostCollections.docs
    .filter((doc: PostCollection) => {
      return doc.slug === 'adulthood' || 'working-adult' || 'getting-started';
    })
    .reduce((acc, doc: PostCollection) => {
      return doc.id;
    });

  const { data: postsByCollection } = await apolloClient.query({
    query: POSTS,
    variables: {
      locale,
      limit: 9,
      draft: false,
      publishedOn: formatISO(Date.now()),
      postCollection: defaultCollection.id,
    },
  });

  let { data: featuredPosts } = await apolloClient.query({
    query: FEATURED_HOME,
    variables: {
      locale,
    },
  });

  const latestPostDocs = [...(latestPosts?.Posts?.docs || [])];

  const postsByCollectionDocs = [...(postsByCollection?.Posts?.docs || [])];

  const sliderFeaturedPostDocs = [
    ...(featuredPosts?.FeaturedPost?.homeSlider || []),
  ];

  await getYoutubeData(latestPostDocs);
  await getYoutubeData(postsByCollectionDocs);
  await getYoutubeData(sliderFeaturedPostDocs, 'post');

  const sliderFeaturedPost = {
    homePage: featuredPosts?.FeaturedPost?.homePage,
    homeSlider: sliderFeaturedPostDocs,
  };

  return addApolloState(apolloClient, {
    props: {
      page: data?.Pages?.[0] || null,
      posts: latestPostDocs || null,
      featuredPosts: sliderFeaturedPost || null,
      postsByCollection: postsByCollectionDocs || null,
      mainMenu: data?.MainMenu || null,
      topBar: data?.TopBar || null,
      footer: data?.Footer || null,
      categories: data?.Categories?.docs || null,
      postCollections: data?.PostCollections?.docs || null,
      collection: 'pages',
      id: data?.Pages?.[0]?.id || null,
      ...(await serverSideTranslations(locale ?? 'en', ['common'])),
    },
  });
};

export default Index;

export async function getYoutubeData(arr: any[], key?: string) {
  for (let i = 0; i < arr.length; i++) {
    const doc = key ? arr[i][key] : arr[i];
    if (doc.type === 'video' && doc.youtube_id) {
      const youtube_id = doc.youtube_id;

      const url = `${process.env.NEXT_PUBLIC_CMS_URL}/api/youtube?id=${youtube_id}`;
      const youtubeRes = await fetch(url);
      const youtubeJson = await youtubeRes.json();

      const videoData: YouTubeResponse = youtubeJson.data;
      const item = videoData?.items?.[0] ?? ({} as YouTubeResponse['items'][0]);
      const videoDuration = item?.contentDetails?.duration ?? '0';

      const videoDurationData =
        videoDuration !== '0'
          ? convertISO8601ToMinutesSeconds(videoDuration)
          : '';

      if (videoDurationData) {
        if (key) {
          arr[i] = { [key]: { ...doc, videoDurationData: videoDurationData } };
        } else {
          arr[i] = { ...doc, videoDurationData: videoDurationData };
        }
      }
    }
  }
}
